<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_subscription_custom') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="name" v-model="subscription.name" :label="translate('name')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('duration')" @wheel="$event.target.blur()" dense square color="primary" type="number" id="duration" v-model.number="subscription.duration" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input :label="translate('credits')" @wheel="$event.target.blur()" dense square color="primary" type="number" id="credits" min="0" v-model.number="subscription.credit" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('custom_subscription_price')" dense square color="primary" @wheel="$event.target.blur()" type="number" step="0.01" id="access_price" v-model.number="subscription.access_price">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                        </div>
                        <div>
                            <q-input class="m-0" :label="translate('description')" v-model="subscription.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="pt-0">
                        <div class="pt-2 pb-2 font-weight-bold">
                            {{ translate('services') }}
                        </div>
                        <template v-if="included_services && included_services.length">
                            <q-item class="bordered-item" clickable ripple v-for="(service,i) in included_services" :key="'included-service-' + i + service.id">
                                <q-item-section side>
                                    <div class="image-thumbnail m-0">
                                        <img v-if="service.main_image_link" :src="service.main_image_link" />
                                        <i v-else class="fa fa-photo fa-2x"></i>
                                    </div>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ service.name }}</q-item-label>
                                    <q-item-label v-if="service.new_number" caption>{{ translate('credits') }}: {{ service.new_number }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deleteItemFromIncluded(i, 'service')" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <q-item @click.prevent="showAddServicesPanel()" v-ripple hoverable clickable class="border align-items-center full-width pr-3 justify-items-spacebetween">
                            <q-item-label>
                                <strong>{{ translate('include_services') }} ({{ included_services.length }})</strong>
                            </q-item-label>
                            <q-space class="pr-2" />
                            <q-item-section side class="pr-0">
                                <q-btn flat round class="shadow-2 m-0 p-1 bg-primary">
                                    <i class="fas fa-2x fa-swimmer"></i>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerCustomSubscription">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import AddServices from './AddServicesNewSubscription'

export default {
    name: 'AddCustomSubscription',
    props: ['no_heade_icon'],
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            included_services: [],
            showCustom: false,
            showPredefined: false,
            checkedPredefined: false,
            checkedCustom: false,
            subscription: {
                name: null,
                duration: null,
                access_price: null,
                credit: null,
                description: '',
            },
            msg: null,
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        eventBus.$on('update_emit_list_with_included_custom_subscription_service', (item) => {
            this.addServiceToList(item)
        })
        eventBus.$on('remove_from_included_list_custom_subscription_service', (item) => {
            this.deleteItemFromIncludedItem(item, 'service')
        })
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        deleteItemFromIncludedItem: function (item, item_type) {
            if (item_type === 'service') {
                var objIndex = this.included_services.findIndex((obj => obj.id === item.id))
                this.included_services.splice(objIndex, 1)
            }
            return false
        },
        deleteItemFromIncluded: function (index, item_type) {
            if (item_type === 'service') {
                this.included_services.splice(index, 1)
            }
            return false
        },
        addServiceToList: function (item) {
            if (!this.included_services.includes(item) && item && item.new_number) {
                this.included_services.push(item)
            }
            return false
        },
        showAddServicesPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddServices,
                props: {
                    baseUrl: baseUrl,
                    subscription_type: 'custom',
                    initial_included_services: this.included_services
                }
            })
        },
        closePanel() {
            this.$emit('closePanel')
            eventBus.$emit('close_panel')
        },
        registerCustomSubscription: function () {
            var data = {}

            if (this.subscription.name) {
                data['name'] = this.subscription.name
            }
            if (this.subscription.duration) {
                data['duration'] = this.subscription.duration
            }
            if (this.subscription.credit) {
                data['included_credits'] = this.subscription.credit
            }
            if (this.subscription.access_price) {
                data['price'] = this.subscription.access_price
            }
            if (this.subscription.description) {
                data['description'] = this.subscription.description
            }
            
            data['services'] = []
            if (this.included_services && this.included_services.length) {
                this.included_services.forEach(element => {
                    data['services'].push(parseInt(element.id))
                })
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }

            axios.post(baseUrl + 'custom-subscriptions', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_custom_subscription_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                        eventBus.$emit('close_panel', {
                            status: true
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
    }
}
</script>
