import { render, staticRenderFns } from "./AddServicesNewSubscription.vue?vue&type=template&id=60f47425&scoped=true&"
import script from "./AddServicesNewSubscription.vue?vue&type=script&lang=js&"
export * from "./AddServicesNewSubscription.vue?vue&type=script&lang=js&"
import style0 from "./AddServicesNewSubscription.vue?vue&type=style&index=0&id=60f47425&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f47425",
  null
  
)

export default component.exports