<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_subscription_predefined') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="name" v-model="subscription.name" :label="translate('name')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('duration')" @wheel="$event.target.blur()" dense square color="primary" type="number" id="duration" v-model.number="subscription.duration" />
                            </div>
                        </div>
                        <div class="pl-3">
                            <div class="text-muted">
                                {{ translate('choose_price_type') }}
                            </div>
                            <div class="p-1">
                                <div class="row m-o">
                                    <div class="col-auto p-0">
                                        <q-radio color="primary" v-model="price_type" value="precalculated" @click.native="priceSettingsPrecalc()" :val="'precalculated'" :label="translate('precalculated')" />
                                    </div>
                                    <div class="col-auto p-0">
                                        <q-radio color="primary" v-model="price_type" @click.native="priceSettingsCust()" value="customazible" :val="'customizable'" :label="translate('customizable')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="show_precalc_fields">
                            <!--[+] Precalculated fields. -->
                            <q-input color="primary" square dense :label="translate('default_price')" @wheel="$event.target.blur()" type="number" step="0.01" id="default_price" v-model.number="subscription.default_price">
                                <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                            </q-input>
                            <div class="list-group-item-accent-info m-0 mt-2 ml-3 alert alert-info text-muted">{{ translate('subscription_default_price_info') }}</div>
                            <!--[-] Precalculated fields. -->
                        </template>
                        <template v-if="show_cust_fields">
                            <!--[+] Customizable fields. -->
                            <q-input color="primary" square dense :label="translate('access_price')" @wheel="$event.target.blur()" type="number" step="0.01" id="access_price" v-model.number="subscription.access_price">
                                <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                            </q-input>
                            <div class="list-group-item-accent-info mt-0 mb-2 mt-2 ml-3 alert alert-info text-muted">{{ translate('subscription_access_price_info') }}</div>

                            <div class="pl-3">
                                <div class="text-muted">
                                    {{ translate('discount_type') }}
                                </div>
                                <div class="p-1">
                                    <div class="row m-o">
                                        <div class="col-auto p-0">
                                            <q-radio color="primary" id="fixed" v-model="subscription.discount_type" :val="'fixed'" :label="translate('fix')" />
                                        </div>
                                        <div class="col-auto p-0">
                                            <q-radio color="primary" v-model="subscription.discount_type" :val="'percentage'" :label="translate('percentage')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <q-input color="primary" square dense :label="translate('discount_price')" @wheel="$event.target.blur()" type="number" step="0.01" id="discount_price" v-model.number="subscription.discount_price" />
                            </div>
                            <!--[-] Customizable fields. -->
                        </template>
                        <div>
                            <q-input class="m-0 mt-2" :label="translate('description')" v-model="subscription.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="pt-0">
                        <div class="pt-2 pb-2 font-weight-bold">
                            {{ translate('services') }}
                        </div>
                        <template v-if="included_services && included_services.length">
                            <q-item class="bordered-item" clickable ripple v-for="(service,i) in included_services" :key="'included-service-' + i + service.id">
                                <q-item-section side>
                                    <div class="image-thumbnail m-0">
                                        <img v-if="service.main_image_link" :src="service.main_image_link" />
                                        <i v-else class="fa fa-photo fa-2x"></i>
                                    </div>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ service.name }}</q-item-label>
                                    <q-item-label v-if="service.new_number" caption>{{ translate('credits') }}: {{ service.new_number }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deleteItemFromIncluded(i, 'service')" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <q-item @click.prevent="showAddServicesPanel()" v-ripple hoverable clickable class="border align-items-center full-width pr-3 justify-items-spacebetween">
                            <q-item-label>
                                <strong>{{ translate('include_services') }} ({{ included_services.length }})</strong>
                            </q-item-label>
                            <q-space class="pr-2" />
                            <q-item-section side class="pr-0">
                                <q-btn flat round class="shadow-2 m-0 p-1 bg-primary">
                                    <i class="fas fa-2x fa-swimmer"></i>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <b-list-group-item class="pt-0">
                        <div class="pt-2 pb-2 font-weight-bold">
                            {{ translate('products') }}
                        </div>
                        <template v-if="included_products && included_products.length">
                            <q-item class="bordered-item" clickable ripple v-for="(product,i) in included_products" :key="'included-product-' + i + product.id">
                                <q-item-section side>
                                    <div class="image-thumbnail m-0">
                                        <img v-if="product.main_image_link" :src="product.main_image_link" />
                                        <i v-else class="fa fa-photo fa-2x"></i>
                                    </div>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ product.name }}</q-item-label>
                                    <q-item-label v-if="product.new_number" caption>{{ translate('quantity') }}: {{ product.new_number }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deleteItemFromIncluded(i, 'product')" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <q-item @click.prevent="showAddProductsPanel()" v-ripple clickable class="border align-items-center full-width pr-3 justify-items-spacebetween">
                            <q-item-label>
                                <strong>{{ translate('include_products') }} ({{ included_products.length }})</strong>
                            </q-item-label>
                            <q-space class="pr-2" />
                            <q-item-section side class="pr-0">
                                <q-btn flat round class="shadow-2 m-0 p-1 bg-primary">
                                    <i class="fas fa-2x fa-box-open"></i>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerPredefinedSubscription">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {eventBus} from '../../../../main'
import AddServices from './AddServicesNewSubscription'
import AddProducts from './AddProductsNewSubscription'

export default {
    name: 'AddPredefinedSubscription',
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            show_precalc_fields: true,
            show_cust_fields: false,
            price_type: 'precalculated',
            included_services: [],
            included_products: [],
            subscription: {
                name: null,
                duration: null,
                access_price: null,
                discount_price: null,
                discount_type: null,
                description: ''
            },
            msg: null,
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        eventBus.$on('update_emit_list_with_included_predefined_subscription_service', (item) => {
            this.addServiceToList(item)
        })
        eventBus.$on('update_emit_list_with_included_predefined_subscription_product', (item) => {
            this.addProductToList(item)
        })
        eventBus.$on('remove_from_included_list_predefined_subscription_service', (item) => {
            this.deleteItemFromIncludedItem(item, 'service')
        })
        eventBus.$on('remove_from_included_list_predefined_subscription_product', (item) => {
            this.deleteItemFromIncludedItem(item, 'product')
        })
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        priceSettingsPrecalc: function () {
            this.show_precalc_fields = !this.show_precalc_fields
            this.show_cust_fields = false
        },
        priceSettingsCust: function () {
            this.show_cust_fields = !this.show_cust_fields
            this.show_precalc_fields = false
        },
        deleteItemFromIncludedItem: function (item, item_type) {            
            if (item_type === 'product') {
                var objIndex = this.included_products.findIndex((obj => obj.id === item.id))
                this.included_products.splice(objIndex, 1)
            }
            if (item_type === 'service') {
                var objIndex = this.included_services.findIndex((obj => obj.id === item.id))
                this.included_services.splice(objIndex, 1)
            }
            return false
        },
        deleteItemFromIncluded: function (index, item_type) {
            if (item_type === 'product') {
                this.included_products.splice(index, 1)
            }
            if (item_type === 'service') {
                this.included_services.splice(index, 1)
            }
            return false
        },
        addServiceToList: function (item) {
            if (!this.included_services.includes(item) && item && item.new_number) {
                this.included_services.push(item)
            }
            return false
        }, 
        addProductToList: function (item) {
            if (!this.included_products.includes(item) && item && item.new_number) {
                this.included_products.push(item)
            }
            return false
        },
        showAddServicesPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddServices,
                props: {
                    baseUrl: baseUrl,
                    subscription_type: 'predefined',
                    initial_included_services: this.included_services
                }
            })
        },
        showAddProductsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddProducts,
                props: {
                    baseUrl: baseUrl,
                    subscription_type: 'predefined',
                    initial_included_products: this.included_products
                }
            })
        },
        closePanel: function () {
            this.$emit('closePanel')
            eventBus.$emit('close_panel')
        },
        registerPredefinedSubscription: function () {
            //+ Define register data: General fields.
            var data = {
                'name': this.subscription.name,
                'duration': this.subscription.duration,
                'description': this.subscription.description
            }
            var data_services = {}
            if (this.included_services && this.included_services.length) {
                this.included_services.forEach(element => {
                    data_services[element.id] = element.new_number
                })
            }
            data['services'] = data_services

            var data_products = {}
            if (this.included_products && this.included_products.length) {
                this.included_products.forEach(element => {
                    data_products[element.id] = element.new_number
                })
            }
            data['products'] = data_products
            //- Define register data: General fields.

            //+ Define register data: (price_type) Precalculated fields.
            if (this.price_type === 'precalculated') {
                data['default_price'] = this.subscription.default_price
            }
            //- Define register data: (price_type) Precalculated fields.

            //+ Define register data: (price_type) Customizable fields.
            if (this.price_type === 'customizable') {
                data['access_price'] = this.subscription.access_price
                data['discount_value'] = this.subscription.discount_price
                data['discount_type'] = this.subscription.discount_type
            }
            //- Define register data: (price_type) Customizable fields.

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }

            axios.post(baseUrl + 'subscriptions', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_standard_subscription_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                        eventBus.$emit('close_panel', {
                            status: true
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
    }
}
</script>
