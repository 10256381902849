<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_promotion') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="registerPromotion">
                    <b-list-group-item class="p-0 pt-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="name" v-model="promotion.name" :placeholder="translate('name')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input @wheel="$event.target.blur()" dense square color="primary" type="number" id="max_usage" v-model.number="promotion.max_usage" :placeholder="translate('max_usage')" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input :label="translate('discount_value')" @wheel="$event.target.blur()" dense square color="primary" type="number" step="0.01" id="discount_value" v-model.number="promotion.discount_value" :placeholder="translate('discount_value')">
                                    <template v-if="promotion.discount_type" v-slot:append><small>{{ promotion.discount_type === 'fixed' ? currency : (promotion.discount_type === 'percentage' ? '%' : '') }}</small></template>
                                </q-input>
                            </div>
                            <div class="col-md pr-0" :class="$q.screen.lt.sm ? 'pl-1' : ''">
                                <div class="pl-2 pt-1 line-height-1">{{ translate('add_promotion_choose_discount_type') }}*</div>
                                <div class="d-flex">
                                    <q-radio color="primary" size="xs" v-model="promotion.discount_type" val="fixed" :label="translate('fix')" />
                                    <q-radio color="primary" size="xs" v-model="promotion.discount_type" val="percentage" :label="translate('percentage')" />
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input @focus="$refs.qDateProxyValidFrom.show()" @click="$refs.qDateProxyValidFrom.show()" @input="$refs.qDateProxyValidFrom.show()" light square color="primary" :label="translate('valid_from')" :placeholder="translate('valid_from')" dense class="full-width" id="valid_from" v-model="promotion.valid_from" no-error-icon :error-message="''">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxyValidFrom" transition-show="scale" transition-hide="scale">
                                                <q-date today-btn first-day-of-week="1" v-model="promotion.valid_from" mask="YYYY-MM-DD" @input="(val) => onUpdateDateValidFrom(promotion.valid_from)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input @focus="$refs.qDateProxyValidUntil.show()" @click="$refs.qDateProxyValidUntil.show()" @input="$refs.qDateProxyValidUntil.show()" light square color="primary" :label="translate('valid_until')" :placeholder="translate('valid_until')" dense class="full-width" id="valid_until" v-model="promotion.valid_until" no-error-icon :error-message="''">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxyValidUntil" transition-show="scale" transition-hide="scale">
                                                <q-date today-btn first-day-of-week="1" v-model="promotion.valid_until" mask="YYYY-MM-DD" @input="(val) => onUpdateDateValidUntil(promotion.valid_until)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="pt-2 pb-0 font-weight-bold">
                        {{ translate('add_promotion_choose_target') }}*
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="service" name="service" class="hidden" v-model="promotion.target_type" value="service" @click="showServiceList" :checkedService="checkedService ? 'checkedService' : ''">
                                <q-icon size="1.5rem" class="mr-1" :color="promotion.target_type === 'service' ? 'primary' : 'secondary'" name="fas fa-swimmer" />
                                {{ translate('service') }} 
                                <q-badge class="p-2 ml-1" color="blue-6" v-if="promotion.target_type === 'service' && checkedService && selected && selected.length > 0">{{ target_values() }}</q-badge>
                            </div>
                            <div class="col-md-auto text-right p-0">
                                <q-input v-if="showServices" @input="isTypingService = true" v-model="searchService" id="filternameservice" class="search-input" type="text" :placeholder="translate('find_service')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddServicePanel" flat dense color="dark" no-caps :label="translate('new_service')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showServices" class="card_scroll m-0 p-0">
                            <div class="list_wrapper-services">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="service in services" :key="'service-' + service.id">
                                    <q-item-section>
                                        <q-item-label>{{ service.name }}</q-item-label>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-checkbox size="md" color="primary" :val="service" emit-value v-model="selected" :id="service_value(service.id)" />
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infServices" slot="append" @infinite="getServices" />
                        </b-card-body>
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="product" name="product" class="hidden" v-model="promotion.target_type" value="product" @click="showProductList" :checkedProduct="checkedProduct ? 'checkedProduct' : ''">
                                <q-icon size="1.5rem" class="mr-1" :color="promotion.target_type === 'product' ? 'primary' : 'secondary'" name="fas fa-box" />
                                {{ translate('product') }}
                                <q-badge class="p-2 ml-1" color="blue-6" v-if="promotion.target_type === 'product' && checkedProduct && selected && selected.length > 0">{{ target_values() }}</q-badge>
                            </div>
                            <div class="col-md-auto text-right p-0">
                                <q-input v-if="showProducts" @input="isTypingProduct = true" v-model="searchProduct" id="filternameproduct" class="search-input" type="text" :placeholder="translate('find_product')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddProductPanel" flat dense color="dark" no-caps :label="translate('new_product')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showProducts" class="card_scroll m-0 p-0">
                            <div class="list_wrapper">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="product in products" :key="'product-'+product.id">
                                    <q-item-section>
                                        <q-item-label>{{ product.name }}</q-item-label>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-checkbox size="md" color="primary" :val="product" emit-value v-model="selected" :id="product_value(product.id)" />
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infProducts" slot="append" @infinite="getProducts" />
                        </b-card-body>
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="subscription" name="subscription" class="hidden" v-model="promotion.target_type" value="subscription" @click="showSubscriptionList" :checkedSubscription="checkedSubscription ? 'checkedSubscription' : ''">
                                <q-icon size="1.5rem" class="mr-1" :color="promotion.target_type === 'subscription' ? 'primary' : 'secondary'" name="fas fa-atom" />
                                {{ translate('subscription') }}
                                <q-badge class="p-2 ml-1" color="blue-6" v-if="promotion.target_type === 'subscription' && checkedSubscription && selected && selected.length > 0">{{ target_values() }}</q-badge>
                            </div>
                            <div class="col-md-auto text-right p-0">
                                <q-input v-if="showSubscriptions" @input="isTypingSubscription = true" v-model="searchSubscription" id="filternamesubscription" class="search-input" type="text" :placeholder="translate('find_subscription')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddSubscriptionPanel('predefined')" flat dense color="dark" no-caps :label="translate('new_subscription')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showSubscriptions" class="card_scroll m-0 p-0">
                            <div class="list_wrapper">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="subscription in subscriptions" :key="subscription.type+'-subscription-'+subscription.id">
                                    <q-item-section>
                                        <q-item-label>{{ subscription.name }}</q-item-label>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-checkbox size="md" color="primary" :val="subscription" emit-value v-model="selected" :id="subscription_value(subscription.id)" />
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infSubscriptions" slot="append" @infinite="getSubscriptions" />
                        </b-card-body>
                    </b-list-group-item>
                    <b-list-group-item class="pt-2 pb-0 font-weight-bold">
                        {{ translate('add_promotion_choose_promotion_type') }}*
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="promo_type" name="promo_type" class="custom-control-input" v-model="promotion.promo_type" value="promo_code" :checkedPromo="checkedPromo ? 'checkedPromo' : ''">
                            <label class="custom-control-label cursor-pointer" for="promo_type">{{ translate('promo_type') }}</label>
                        </div>
                        <q-card v-if="promotion.promo_type === 'promo_code'" class="p-2 mt-3 mb-2">
                            <q-input ref="promoCode" dense square color="primary" type="text" id="promo_code" v-model="promotion.promo_code" :label="translate('promo_code')" :placeholder="translate('enter_promocode')" />
                        </q-card>
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="standard" name="standard" class="custom-control-input" v-model="promotion.promo_type" value="standard" :checkedPromo="checkedPromo ? 'checkedPromo' : ''">
                            <label class="custom-control-label cursor-pointer" for="standard">{{ translate('standard_promo') }}</label>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerPromotion">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import AddService from '../../../offers/services/forms/AddService'
import AddProduct from '../../../offers/products/forms/AddProduct'
import AddSubscription from '../../../offers/subscriptions/forms/AddSubscription'

export default {
    name: 'AddPromotion',
    props: ['selected_promo_type', 'focus_input'],
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            infServices: 'inf-services',
            infProducts: 'inf-products',
            infSubscriptions: 'inf-subscriptions',
            showServices: false,
            showProducts: false,
            showSubscriptions: false,
            checkedService: false,
            checkedPromo: false,
            checkedProduct: false,
            checkedSubscription: false,
            promo_code: false,
            standard: false,
            promotion: {
                name: null,
                target_type: null,
                promo_type: this.selected_promo_type ? this.selected_promo_type : null,
                promo_code: null,
                discount_type: null,
                discount_value: null,
                valid_from: 'YYYY-mm-dd',
                valid_until: 'YYYY-mm-dd',
                max_usage: null
            },
            selected: [],
            subscriptions: [],
            products: [],
            services: [],
            page_subscription: 1,
            total_subscription_pages: 1,
            page_service: 1,
            page_product: 1,
            searchService: '',
            searchProduct: '',
            searchSubscription: '',
            isTypingService: false,
            isTypingProduct: false,
            isTypingSubscription: false,
            selected_service_names: [],
            selected_product_names: [],
            selected_subscription_names: [],
        }
    },
    created: function () {
        this.getElementsWidthHeight()
        if (this.selected_promo_type === 'promo_code') {
            this.focusSelectedPromoCode()
        }
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    watch: {
        searchService: _.debounce(function () {
            this.isTypingService = false
        }, 200),
        searchProduct: _.debounce(function () {
            this.isTypingProduct = false
        }, 200),
        searchSubscription: _.debounce(function () {
            this.isTypingSubscription = false
        }, 200),
        isTypingService: function (value) {
            if (!value) {
                this.page_service = 1
                this.services = []
                this.getServices()
            }
        },
        isTypingProduct: function (value) {
            if (!value) {
                this.page_product = 1
                this.products = []
                this.getProducts()
            }
        },
        isTypingSubscription: function (value) {
            if (!value) {
                this.page_subscription = 1
                this.subscriptions = []
                this.getSubscriptions()
            }
        }
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        focusSelectedPromoCode: _.debounce(function () {
            if (this.$refs.promoCode) {
                this.$refs.promoCode.focus()
            }
        }, 200),
        onUpdateDateValidFrom: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxyValidFrom.hide()
            })
        },
        onUpdateDateValidUntil: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxyValidUntil.hide()
            })
        },
        showAddServicePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddService
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetServicesList()
                }
            })
        },
        resetServicesList: function () {
            this.page_service = 1
            this.services = []
            this.infServices++
        },
        showAddProductPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddProduct
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetProductsList()
                }
            })
        },
        resetProductsList: function () {
            this.page_product = 1
            this.products = []
            this.infProducts++
        },
        showAddSubscriptionPanel: function (type) {
            const panelInstance = this.$showPanel({
                component: AddSubscription
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetSubscriptionsList()
                }
            })
        },
        resetSubscriptionsList: function () {
            this.page_subscription = 1
            this.subscriptions = []
            this.infSubscriptions++
        },
        resetOfferType: function () {
            this.showServices = false
            this.checkedService = false
            this.showSubscriptions = false
            this.checkedSubscription = false
            this.showProducts = false
            this.checkedProduct = false
            this.selected = []
            this.selected_service_names = []
            this.selected_product_names = []
            this.selected_subscription_names = []
        },
        showServiceList: function () {
            if (!this.checkedService) {
                this.resetOfferType()
                this.checkedService = true
                localStorage.removeItem('radio_subscription')
                localStorage.removeItem('radio_product')
                localStorage.setItem('radio_service', this.checkedService)
            }
            this.showServices = !this.showServices
        },
        showProductList: function () {
            if (!this.checkedProduct) {
                this.resetOfferType()
                this.checkedProduct = true
                localStorage.removeItem('radio_subscription')
                localStorage.removeItem('radio_service')
                localStorage.setItem('radio_product', this.checkedProduct)
            }
            this.showProducts = !this.showProducts
        },
        showSubscriptionList: function () {
            if (!this.checkedSubscription) {
                this.resetOfferType()
                this.checkedSubscription = true
                localStorage.removeItem('radio_product')
                localStorage.removeItem('radio_service')
                localStorage.setItem('radio_subscription', this.checkedSubscription)
            }
            this.showSubscriptions = !this.showSubscriptions
        },
        service_value: function (element_id) {
            return 'service_' + element_id
        },
        subscription_value: function (element_id) {
            return 'subscription_' + element_id
        },
        product_value: function (element_id) {
            return 'product_' + element_id
        },
        promoCode: function () {
            if (!this.checkedPromo) {
                this.checkedPromo = true
                this.promo_code = true
                localStorage.setItem('tutorial', this.checkedPromo)
            }
        },
        Standard: function () {
            if (this.checkedPromo) {
                this.checkedPromo = false
                this.promo_code = false
                localStorage.removeItem('tutorial')
            }
        },
        getSubscriptions: function ($state) {
            var subscriptionUrl = baseUrl +
                'promotions/subscriptions?page=' + this.page_subscription +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + (this.searchSubscription ? this.searchSubscription : '')
            var subscriptionUrlCustom = baseUrl +
                'promotions/custom-subscriptions?page=' + this.page_subscription +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + (this.searchSubscription ? this.searchSubscription : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(subscriptionUrl, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        this.subscriptions.push(...response.data.items)
                    }
                    this.total_subscription_pages = response.data.total_pages
                    if (this.page_subscription < response.data.total_pages) {
                        this.page_subscription++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                }).then(() => {
                    // Get the Custom Subscriptions, if it's on the last page, and push to the existing list.
                    if (this.page_subscription >= this.total_subscription_pages) {
                        axios.get(subscriptionUrlCustom, {
                                headers: headers
                            })
                            .then(response => {
                                if (response.data.items && response.data.items.length > 0) {
                                    this.subscriptions.push(...response.data.items)
                                }
                                if (this.page_subscription >= response.data.total_pages) {
                                    this.page_subscription++
                                    $state.loaded()
                                } else {
                                    $state.complete()
                                }
                            })
                            .catch(function (error) {
                                // console.log(error)
                            })
                    }
                })
        },
        getProducts: function ($state) {
            var productnUrl = baseUrl +
                'promotions/products?page=' + this.page_product +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchProduct
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(productnUrl, {
                    headers: headers
                })
                .then(response => {
                    if (this.page_product <= response.data.total_pages) {
                        setTimeout(() => {
                            this.page_product++
                            this.products.push(...response.data.items)
                            $state.loaded()
                        }, 200)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getServices: function ($state) {
            var serviceUrl = baseUrl +
                'promotions/services?page=' + this.page_service +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchService
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(serviceUrl, {
                    headers: headers
                })
                .then(response => {
                    if (this.page_service <= response.data.total_pages) {
                        setTimeout(() => {
                            this.page_service++
                            this.services.push(...response.data.items)
                            $state.loaded()
                        }, 200)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        targetData: function () {
            return this.selected.map(elem => {
                let obj = {}
                obj.target_id = elem.id

                // if item has type, append
                if (undefined !== elem.type)
                    obj.target_type = elem.type

                return obj
            })
        },
        target_values: function () {
            return this.selected.map(item => { return item.name } ).join(', ')
        },
        registerPromotion: function () {
            var data = {
                'name': this.promotion.name,
                'target_type': this.promotion.target_type,
                'target_data': this.targetData(),
                'promo_type': this.promotion.promo_type,
                'promo_code': this.promotion.promo_code,
                'discount_type': this.promotion.discount_type,
                'discount_value': this.promotion.discount_value,
                'valid_from': this.promotion.valid_from,
                'valid_until': this.promotion.valid_until,
                'max_usage': this.promotion.max_usage
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'promotions', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_promotion_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: calc(50px * 3);
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    .q-checkbox {
        transform: scale(0.85);
    }
}

.form-content {
    max-height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
}

body .list_wrapper .q-item.row {
    width: 100%;
}

.included-services-block {
    padding: 0.5rem !important;
    background-color: #defffa;
    border-top: 2px solid #59a9c7;
    border-bottom: 2px solid #59a9c7;
}
</style>
