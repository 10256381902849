<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute closePanelServicesSub" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fas fa-lg fa-swimmer" />
            </template>
            <b-form @submit.prevent="includeBulkServiceValues">
                <b-list-group class="list-group-accent">
                    <b-list-group-item @click.prevent="isHidden = !isHidden" class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer">
                        <q-icon :title="translate('Click to show or hide the included services')" name="list" />&nbsp;
                        <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                            {{translate('included_services')}}
                            <q-badge color="primary" floating>{{ included_services && included_services.length ? included_services.length : '0' }}</q-badge>
                        </strong>
                    </b-list-group-item>
                    <b-list-group-item v-if="!isHidden && included_services && included_services.length" class="list-group-item-accent-info">
                        <div class="list-container-scroll vh-31">
                            <q-item class="list-item" clickable ripple v-for="(service,i) in included_services" :key="'included-service-' + i + service.id">
                                <q-item-section side>
                                    <div class="image-thumbnail m-0">
                                        <img v-if="service.main_image_link" :src="service.main_image_link" />
                                        <i v-else class="fa fa-photo fa-2x"></i>
                                    </div>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ service.name }}</q-item-label>
                                    <q-item-label v-if="subscription_type !== 'custom' ? service.new_number :null" caption>{{ translate('credits') }}: {{ service.new_number }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deleteItemFromIncluded(i, 'service', service)" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </div>
                        <div v-if="!included_services">
                            <q-item class="m-0 pt-3">{{ translate('No included services') }}</q-item>
                        </div>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                        <q-icon name="add" />&nbsp;
                        {{translate('include_service')}}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <include-item-form :max_number="max_number" class="form-includes" :included_items="included_services.map(included => {return included.id})" :search_placeholder="translate('find_service')" :item_type="subscription_type" :baseUrl="baseUrl" :post_url="null" :get_url="get_url" :entity_type="entity_type">
                            <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddServicePanel" flat dense slot="add_button" color="dark" no-caps :label="translate('new_service')" icon="add" />
                        </include-item-form>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn v-if="subscription_type !== 'custom'" type="submit" no-caps color="primary">
                                    <q-icon size="1rem" name="add" class="mr-1" /> {{ translate('include') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section>
                            </q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="secondary" class="text-dark">
                                    <q-icon size="1rem" name="cancel" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-list-group>
            </b-form>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from "../../../../main"

import AddService from '../../../offers/services/forms/AddService'
import IncludeItemForm from '../../../template-parts/EmitListItemForm'

export default {
    name: 'AddServicesNewSubscription',
    props: ['baseUrl', 'subscription_type', 'initial_included_services'],
    components: {
        'include-item-form': IncludeItemForm
    },
    computed: {
        max_number_error: function () {
            return this.translate('max_amount_allowed')  + ' ' + this.max_number
        },
        entity_type: function () {
            return this.subscription_type + '_subscription_service'
        }
    },
    mounted: function () {
        eventBus.$on('update_emit_list_with_included_predefined_subscription_service', (item) => {
            this.addServiceToList(item)
        })
        eventBus.$on('update_emit_list_with_included_custom_subscription_service', (item) => {
            this.addServiceToList(item)
        })
    },
    data: function () {
        return {
            max_number: '1',
            included_services: this.initial_included_services && this.initial_included_services.length ? this.initial_included_services : [],
            get_url: this.getGetUrl(),
            isHidden: false,
            item_ids: []
        }
    },
    methods: {
        showAddServicePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddService,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_all')
                }
            })
        },
        validateBulkMaxNumber: function (number_inputs) {
            var is_valid = true
            number_inputs.forEach(element => {
                if (element.value > this.max_number && element.getAttribute('data-type') === 's_streaming') {
                    this.$toasted.error(this.max_number_error, {duration: this.$toasted_duration})
                    is_valid = false
                }
            })
            return is_valid
        },
        includeBulkServiceValues: function (submitEvent) {
            var number_inputs = submitEvent.target.querySelectorAll('.form-includes input[type="number"]')

            if (this.validateBulkMaxNumber(number_inputs) === false) {
                return false
            }

            number_inputs.forEach(element => {
                if (element.value) {
                    this.item_ids.push(element.getAttribute('data-item-id'))
                }
            })
            
            if (this.item_ids) {
                eventBus.$emit('update_emit_items', this.item_ids)
                this.closePanel()
                return
            }
        },
        addServiceToList: function (item) {
            if (!this.included_services.includes(item)) {
                this.included_services.push(item)
            }
        },
        addServiceToListDebounce: _.debounce(function (item) {
            if (!this.included_services.includes(item)) {
                this.included_services.push(item)
            }
        }, 200),
        deleteItemFromIncluded: _.debounce(function (index, item_type, item) {
            if (item_type === 'service' && item && item.new_number) {
                this.included_services.splice(index, 1)
                eventBus.$emit('add_to_to_include_list_' + this.entity_type, item)
                eventBus.$emit('remove_from_included_list_' + this.entity_type, item)
            }
            return false
        }, 200),
        getGetUrl: function() {
            return 'subscriptions/available_services'
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
