<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_product') }}</strong>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <q-input dense square color="primary" type="text" id="name" v-model="product.name" :placeholder="translate('name')" />
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input color="primary" square dense @wheel="$event.target.blur()" type="number" step="0.01" id="basic_price" v-model.number="product.base_price" :placeholder="translate('basic_price')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                            <div class="col-md p-0">
                                <q-input color="primary" square dense @wheel="$event.target.blur()" type="number" step="0.01" id="subscription_price" v-model.number="product.subscription_price" :placeholder="translate('subscription_price')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                        </div>
                        <q-input :placeholder="translate('description')" v-model="product.description" square dense color="primary" type="textarea" rows="2" />
                        <div class="pl-3 pr-3">
                            <small class="text-uppercase">{{ translate('images') }}</small>
                            <b-form-file type="file" name="selectedFiles" ref="selectedFiles" multiple @change="onFilesSelected" v-model="selectedFiles" />
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerProduct">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddProduct',
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            product: {
                name: null,
                description: '',
                base_price: null,
                subscription_price: null,
            },
            msg: null,
            selectedFiles: null,
            selected: null,
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        wheelIt: function ( event ) {
            event.target.blur()
        },
        onFilesSelected: function (event) {
            this.selectedFiles = event.target.files[0]
        },
        registerProduct: function () {
            const formData = new FormData()

            if (this.selectedFiles) {
                this.selectedFiles.map((file, index) => {
                    formData.append(`file${index}`, file)
                })
            }

            if (this.product.name) {
                formData.set('name', this.product.name)
            }
            if (this.product.description) {
                formData.set('description', this.product.description)
            }
            if (this.product.base_price) {
                formData.set('base_price', this.product.base_price)
            }
            if (this.product.subscription_price) {
                formData.set('subscription_price', this.product.subscription_price)
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }

            axios.post(baseUrl + 'products', formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_product_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
</style>
