<template>
<div class="animated fadeIn fit">
    <q-tabs clickable hoverable no-caps v-model="tab" align="left" class="no-controls">
        <q-tab class="custom-tab text-primary" name="predefined">
            <strong class="text-dark">{{ translate('predefined_subscription') }}</strong>
        </q-tab>
        <q-tab class="custom-tab text-primary" name="custom">
            <strong class="text-dark">{{ translate('custom_subscription') }}</strong>
        </q-tab>
    </q-tabs>
    <b-card no-header no-body class="m-0 mb-4 p-0 overflow-hidden">
        <q-tab-panels v-model="tab">
            <q-tab-panel class="p-0" name="predefined">
                <predefined />
            </q-tab-panel>

            <q-tab-panel class="p-0" name="custom">
                <custom />
            </q-tab-panel>
        </q-tab-panels>
    </b-card>
</div>
</template>

<script>
import { eventBus } from '../../../../main'
import Predefined from './AddPredefinedSubscription'
import Custom from './AddCustomSubscription'

export default {
    name: 'AddSubscription',
    components: {
        'predefined': Predefined,
        'custom': Custom,
    },
    data: function () {
        return {
            show_tabs: true,
            tab: 'predefined',
        }
    },
    mounted: function () {
        eventBus.$on('close_panel', (status) => {
            this.$emit('closePanel', {
                status: status
            })
        })
    }
}
</script>
